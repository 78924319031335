import React, { useEffect, useLayoutEffect, useRef, useCallback } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { AppRoute } from 'router';
import { Amplify } from 'aws-amplify';
import { useAuthStore, useLinkedAccountStore, usePlaidStore } from 'store';
import {
  getLinkedAccount,
  getLinkedAccountMappingFailed,
  getMe,
  getMySubscriptions,
  getProfile,
  getUserConfig
} from 'services';
import { AppLoading, ModalUpdateUser } from 'components';
import { useAppStore } from 'store/appStore';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDetectIdleTimer, useListenerRewards } from 'hooks';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_DOMAIN_SOCIAL_AUTHEN_URL,
      redirectSignIn: isLocalhost ? 'http://localhost:8668' : process.env.REACT_APP_REDICRECT_SOCIAL_AUTHEN_URL,
      redirectSignOut: isLocalhost ? 'http://localhost:8668' : process.env.REACT_APP_REDICRECT_SOCIAL_AUTHEN_URL,
      responseType: 'code'
    }
  }
});

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  useDetectIdleTimer();

  const { setUser, token, loading, setLoading, setUserConfig, setToken, user, setUserProfile } = useAuthStore();
  const { appLoading, setSubscriptions, setAppLoading, setRewardsSummary } = useAppStore();
  const { setShowPlaidLoader, setProgress, progress, setShowProgress } = usePlaidStore();
  const { setLinkedData } = useLinkedAccountStore();

  const callbackRef = useRef<((data: any) => void) | undefined>(undefined);

  const refreshDataLinkedPlaid = useCallback(async () => {
    try {
      setAppLoading(true);
      const resp = await getMe();
      setUser(resp);
      const respLinked = await getLinkedAccount();
      setLinkedData({ ...respLinked?.data });
      const mappingResp = await getLinkedAccountMappingFailed();
      if (mappingResp?.data?.length > 0) {
        setAppLoading(false);
        navigate('/linked-account/card-validation');
        return;
      }

      setAppLoading(false);
    } catch (err: any) {
      console.log(err);
      toast.error(err.response.data.message || 'Something went wrong');
      setAppLoading(false);
    }
  }, []);

  const updateCallback = useCallback(
    (data: any) => {
      console.log('data socket: ', data);

      const dataSocket = data?.data;
      const loading = dataSocket?.loading;
      const messageSocket = data?.message;

      if (messageSocket === 'FAILED') {
        toast.error(`Something went wrong, calculatedReward status ${messageSocket}`);
        setShowPlaidLoader(false);
        setProgress(0);
        return;
      }

      // Use the latest user state
      const currentUser = user;
      const currentProgress = progress;

      console.log('user has linked plaid 1: ', currentUser?.hasLinkedPlaid);
      if (!currentUser?.hasLinkedPlaid) {
        console.log('user has linked plaid: ', currentUser?.hasLinkedPlaid);
        if (loading !== 100) {
          setShowPlaidLoader(true);
        }
        if (Object.prototype.hasOwnProperty.call(dataSocket, 'earnedTotal')) {
          setRewardsSummary({ earnedTotal: dataSocket?.earnedTotal || 0, missedTotal: dataSocket?.missedTotal || 0 });
          setShowPlaidLoader(false);
        }
        if (loading === 100) {
          console.log('data reward: ', data);
          setShowPlaidLoader(false);
          refreshDataLinkedPlaid();
        }
      } else {
        if (Object.prototype.hasOwnProperty.call(dataSocket, 'loading')) {
          if (loading >= currentProgress) {
            setProgress(loading);
          }
          if (loading !== 100) {
            setShowProgress(true);
          }
          if (loading === 100) {
            console.log('data reward: ', data);
            setShowPlaidLoader(false);
            setShowProgress(false);
            refreshDataLinkedPlaid();
          }
        } else if (Object.prototype.hasOwnProperty.call(dataSocket, 'earnedTotal')) {
          setRewardsSummary({ earnedTotal: dataSocket?.earnedTotal || 0, missedTotal: dataSocket?.missedTotal || 0 });
          setShowPlaidLoader(false);
          setShowProgress(false);
          setProgress(0);
        }
      }
    },
    [user, progress]
  );

  useEffect(() => {
    callbackRef.current = updateCallback;
  }, [updateCallback]);

  const { triggerConnect } = useListenerRewards((data) => {
    if (callbackRef.current) {
      callbackRef.current(data);
    }
  });

  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  }, [location.pathname]);

  useEffect(() => {
    if (!location?.search) return;
    const queryParams = new URLSearchParams(location?.search);
    const tokenParams = queryParams.get('token');
    if (!tokenParams) return;
    setToken(tokenParams);
    navigate('/');
  }, [location]);

  const fetchData = async () => {
    try {
      setLoading(true);

      const resps = await Promise.all([getMe(), getUserConfig(), getMySubscriptions(), getProfile()]);

      setUser(resps[0]);
      setUserConfig(resps[1]);
      setSubscriptions(resps[2]);
      setUserProfile(resps[3]?.data);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      fetchData();
      triggerConnect();
    }
  }, [token]);

  return (
    <>
      {(loading || appLoading) && <AppLoading />}
      <AppRoute />
      <ToastContainer autoClose={2000} toastStyle={{ top: 90 }} />
      {user && token && <ModalUpdateUser isOpen={!user?.accountVerification?.accountVerification} />}
    </>
  );
}

export default App;
